/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { shape, string } from 'prop-types';
import { uid } from 'react-uid';
import ReactHtmlParser from 'react-html-parser';
import { makeStyles } from '@material-ui/core/styles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Button } from '@material-ui/core';
import PmallPersonalizationDialog from './PmallPersonalizationDialog';
import { getCartAttributeByNameAndReturnValue } from '../../../../../../../helpers/page/pageHelpers';

const useStyles = makeStyles((theme) => ({
    reviewDetails: {
        borderTop: '1px dotted rgba(213, 213, 213, 1)',
        paddingTop: '0.5rem',
    },
    header: {
        fontSize: '0.875rem',
        fontWeight: '400',
        color: theme.palette?.primary?.main,
    },
    expandIcon: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingBottom: '0.5rem',
    },
    reviewText: {
        fontSize: '0.75rem',
        fontWeight: '400',
        color: theme.palette.cta4 || '#595959',
        paddingTop: '0.5rem',
    },
    btnCta: {
        color: 'rgb(0,0,238)',
        padding: '0',
        textTransform: 'none',
        fontSize: '0.75rem',
        lineHeight: '22px',
        fontWeight: '400',
        minWidth: '1.875rem',
        textDecoration: 'underline',
        '&:hover': {
            background: 'transparent',
            textDecoration: 'underline',
        },
    },
}));

const PersonalizationLineSummary = ({ itemDetails }) => {
    const classes = useStyles();
    const [isOpen, setModal] = useState(true);
    const [pmallModalOpen, setPmallModalOpen] = useState(false);

    const pdpGCIPersonalization = itemDetails?.addons
        ? (itemDetails.addons).filter((addon) => (addon.itemInfo.lineItemType === 'PERSONALIZATION'))
        : [];

    let personalizationLines = [];
    if (itemDetails.personalization?.personalizationLines) {
        personalizationLines = itemDetails.personalization.personalizationLines;
    }

    if (
        !itemDetails.personalization
        || !['PRODUCT_PERSONALIZATION', 'PRODUCT_PERSONALIZATION_GCI', 'EXTERNAL'].includes(itemDetails.personalization.personalizationType)
    ) {
        return null;
    }
    return (
        <>
            <Grid item xs={12} className={classes.expandIcon}>{isOpen ? <ExpandLess data-testid="expand-less-icon" onClick={() => setModal(false)} /> : <ExpandMore data-testid="expand-more-icon" onClick={() => setModal(true)}  />}</Grid>
            {isOpen && (
                <Grid container className={classes.reviewDetails}>
                    <Grid item xs={12} className={classes.header}>Personalization: </Grid>
                    <Grid item xs={12} className={classes.reviewText}>
                        {
                            pdpGCIPersonalization.map((persItem, key) => {
                                const keyId = `persGCI-${key}`;
                                return (
                                    <span key={keyId}>
                                        Text: {getCartAttributeByNameAndReturnValue(persItem.attributes, 'text')}<br />
                                    </span>
                                );
                            })
                        }
                        {personalizationLines.map((value, key) => {
                            let line = '';
                            if (value?.perAttributeId === 'previewUrl') {
                                line =   (
                                    <>
                                        <a target="_blank" href={value.perAttributeValue} rel="noreferrer">Preview</a> /
                                        <Button
                                            id="edit-pmall-personalization"
                                            className={classes.btnCta}
                                            data-test="edit-pmall-personalization"
                                            aria-label="Personalization Pmall Edit"
                                            onClick={() => setPmallModalOpen(true, itemDetails?.personalization?.personalizationId, itemDetails.cartItemId)}
                                        >
                                            Edit
                                        </Button>
                                    </>
                                );
                            } else {
                                line = typeof value === 'object' ? ReactHtmlParser(value.perAttributeValue) : ReactHtmlParser(value);
                            }
                            return (
                                <>
                                    <span key={uid(value, key)}>
                                        Line {(key + 1)}: {line}<br />
                                    </span>
                                </>
                            );
                        })}
                    </Grid>
                </Grid>
            )}
            {pmallModalOpen && (
                <PmallPersonalizationDialog
                    isOpen={pmallModalOpen}
                    product={itemDetails}
                    handleModalPmall={setPmallModalOpen}
                    pageType="checkout-food"
                />
            )}
        </>
    );
};

PersonalizationLineSummary.propTypes = {
    itemDetails: shape({
        comments: string,
        cartItemId: string,
        product: shape({
            skuCode: string,
            brandCode: string,
        }),
    }).isRequired,
};

export default (PersonalizationLineSummary);
