/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    func, string, shape, bool,
} from 'prop-types';
import {
    Button, DialogTitle, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Typography,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import RemoveIcon from '@material-ui/icons/RemoveCircleTwoTone';
import { useSelector, useDispatch } from 'react-redux';
import index from 'mbp-logger';
import {
    getIsProductFilterZipcodeValid,
    getWineData,
} from '../../../../../../state/ducks/App/App-Selectors';
import AgeVerificationForm from './partials/AgeVerificationForm';
import GraphqlWineCompliance from '../GraphqlAddon/Partials/GraphqlWineCompliance';
import { getBrand } from '../../../../../pages/Account/state/ducks/Common/Common-Selectors';
import GraphqlWineRestrictions from './partials/GraphqlWineRestrictions';
import { resetWineData } from '../../../../../../state/ducks/App/App-Actions';

const useStyles = makeStyles((theme) => ({
    contDrawer: {
        overflowX: 'hidden',
        height: '578px',
        width: '397px',
        borderRadius: '0 10px 10px 0',
        padding: '3px 5px 0px 17px',
        backgroundColor: 'rgba(242, 242, 242, 1)',
        position: 'absolute',
    },
    drawer: {
        backgroundColor: theme.palette.white,
        zIndex: '200',
    },
    dialogContent: {
        '@media screen and (max-width: 600px)': {
            paddingTop: '8px 21px',
        },
        '&:first-child': {
            padding: '0px',
        },
    },
    GoBackBtn: {
        color: theme.palette.black,
        marginRight: '22px',
        fontFamily: theme.typography.fontFamily,
        boxSizing: 'border-box',
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
        border: `1px solid ${theme.palette.cms?.black || '#2f2f2f'}`,
        fontSize: '1rem',
        textTransform: 'uppercase',
        lineHeight: '1.46em',
        padding: '8px 22px',
        borderRadius: 'inherit',
    },
    buttonContainer: {
        display: 'flex',
        '@media screen and (max-width: 600px)': {
            marginTop: '30px',
        },
        '& .MuiButton-root.Mui-disabled': {
            backgroundColor: theme.palette.cms?.grey || '#b9b8b8',
            color: theme.palette.cms?.white || '#ffffff',
            border: 'inherit',
        },
    },
    addonsVerificationMobile: {
        position: 'relative',
        margin: '10px',
        '& > div': {
            padding: '10px',
        },
        '& h3': {
            fontSize: '1.125rem',
        },
        '& .age-verification::before': {
            top: '0px',
        },
        '& .age-verification::after': {
            top: '-25px',
        },
        '& .zip-code-error::after': {
            top: '0px',
        },
    },
    addonsVerification: {
        position: 'relative',
        maxWidth: '950px',
        borderRadius: 'inherit',
        '& .zip-code-success': {
            '&::before': {
                content: 'inherit',
            },
            '&::after': {
                top: '28px',
                left: '15px',
            },
        },
    },
    dialogHeader: {
        boxShadow: '0px 3px 3px rgb(47 47 47 / 15%)',
        padding: '8px 24px',
        '& h2': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
    },
    header: {
        textTransform: 'capitalize',
        fontSize: '1rem',
        lineHeight: '1.5rem',
    },
    mobileModal: {
        padding: '25px 8px 0px 0px',
        '@media screen and (max-width: 600px)': {
            paddingTop: '20px',
        },
        '& .input-field': {
            minHeight: '45px',
            marginLeft: '12px',
        },
        '& .zip-code-box': {
            paddingTop: '10px !important',
        },
        '& .age-verification.success::after': {
            top: '0px',
            left: '20px',
            '@media screen and (max-width: 600px)': {
                top: '10px',
            },
        },
        '& .age-verification.success::before': {
            top: '-3px',
            left: '0px',
        },
        '& .age-verification.error::after': {
            margin: '0px',
            left: '12px',
            color: theme.palette.cms?.errorMessage || '#DF3030',
            backgroundColor: theme.palette.cms?.white || 'white',
            border: `1px solid ${theme.palette.cms?.errorMessage || '#DF3030'}`,
            top: '2px',
            width: '15px',
            height: '15px',
            lineHeight: '8px',
            padding: '3px 0px',
            fontSize: '10px',
            '@media screen and (max-width: 600px)': {
                top: '5px',
            },

        },
        '& .age-verification.error::before': {
            content: '"You must be over 21 to order wine products."',
            color: theme.palette.cms?.errorMessage || '#DF3030',
            lineHeight: '19px',
            paddingLeft: '17px',
            fontSize: '14px',
            '@media screen and (max-width: 600px)': {
                fontSize: '0.75rem',
            },
        },
        '& .age-verification.error.error-invalid-date::before': {
            content: '"Please enter a valid date."',
            color: theme.palette.cms?.errorMessage || '#DF3030',
            paddingLeft: '17px',
            fontSize: '14px',
            '@media screen and (max-width: 600px)': {
                fontSize: '0.75rem',
            },
        },
        '& .age-verification.error': {
            '@media (max-width: 767px)': {
                paddingTop: '0px',
            },
            '@media (max-width: 600px)': {
                padding: '4px 0px 8px 15px',
            },
        },

    },
    ageVerificationSummary: {
        margin: '10px 10px 10px 0px !important',
    },
    cardTitleBirth: {
        margin: '10px 10px 5px 0px !important',
        fontSize: '1.25rem',
        lineHeight: '1.75rem',
    },
    cardTitleZipCode: {
        fontSize: '1.25rem',
        lineHeight: '1.75rem',
    },
    topContent: {
        '& h3': {
            margin: '0px',
            fontSize: '1.25rem',
            lineHeight: '1.75rem',
            textAlign: 'left',
        },
    },
    verificationContainer: {
        display: 'flex',
        maxWidth: 'unset !important',
        '@media screen and (max-width: 600px)': {
            display: 'block',
        },
    },
    enterBirthInput: {
        '& >div': {
            '&:after': {
                content: 'inherit',
            },
            '&:before': {
                content: 'inherit',
            },
        },
    },
    enterBirthYearInput: {
        '& >div': {
            '&:after': {
                content: 'inherit',
            },
            '&:before': {
                content: 'inherit',
            },
            '& .Mui-error': {
                display: 'none',
            },
        },
    },
    zipField: {
        '&:after': {
            content: 'inherit',
        },
        '&:before': {
            content: 'inherit',
        },
    },
    birth: {
        width: '65%',
        '@media screen and (max-width: 768px)': {
            width: '100%',
        },
        '@media screen and (max-width: 600px)': {
            width: '100%',
        },
    },
    zipcode: {
        '& .MuiInputBase-input': {
            height: '23px',
            marginTop: '3px',
            width: '90px',
        },
        '@media screen and (max-width: 600px)': {
            paddingTop: '0px !important',
        },
    },
    panelRoot: {
        boxShadow: 'none',
        backgroundColor: 'inherit',
        margin: '28px 0px 0px !important',
        borderBottom: `2px solid ${theme.palette.cms?.productDetailsSummary || '#607767'}`,
        '&:before': {
            display: 'none',
        },
        '&.Mui-expanded': {
            margin: '0px',
            paddingBottom: '10px',
            borderBottom: `2px solid ${theme.palette.cms?.tertiary_hover}`,
        },
    },
    summaryRoot: {
        backgroundColor: 'inherit',
        minHeight: '37px',
        padding: '0px',
        fontSize: '1.125rem',
        '&.Mui-expanded': {
            margin: '0px',
            minHeight: '37px',
            borderBottom: `2px solid ${theme.palette.primary.main || '#2F2F2F'}`,
        },
        '& .MuiExpansionPanelSummary-content': {
            margin: '0px',
        },
    },
    container: {
        display: 'flex',
        fontSize: '1.25rem',
    },
    detailsRoot: {
        padding: '0px',
        fontSize: '0.875rem',
        maxHeight: '312px',
        overflow: 'hidden',
        flexDirection: 'column',
        '& ul': {
            marginTop: '0px',
        },
        '& button': {
            color: theme.palette.primary.main || '#2F2F2F',
            fontWeight: 700,
        },
        '& p': {
            fontSize: '1rem',
            lineHeight: '1.5rem',
        },
    },
    icons: {
        position: 'absolute',
        right: '0px',
        top: '4px',
        '& svg': {
            width: '32px',
            height: '32px',
        },
    },
    ageInput: {
        position: 'relative',
        padding: '0px',
    },
    form: {
        margin: '5px 0px 0px 0px',
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    submitButton: {
        backgroundColor: theme.palette.primary.main || '#2F2F2F',
        color: theme.palette.cms?.white || '#ffffff',
        '&:hover': {
            backgroundColor: theme.palette.primary.main || '#2F2F2F',
        },
    },
    headerContent: {
        fontSize: '1.25rem',
        lineHeight: '1.50rem',
        fontWeight: 700,
    },
    label: {
        fontSize: '0.875rem',
        lineHeight: '1.125rem',
    },
    removeIcon: {
        '& path:first-child': {
            fill: theme.palette.headerColor || '#E8F1ED',
            opacity: 1,
        },
    },
    closeBox: {
        display: 'flex',
        padding: '5px',
        justifyContent: 'end',
        cursor: 'pointer',
    },
}));

const InLineAddonVerification = ({
    zipField,
    dayErrorMessage,
    monthErrorMessage,
    yearErrorMessage,
    zipFieldRef,
    getBirthDay,
    getBirthMonth,
    getBirthYear,
    handleAgeVerification,
    validateFields,
    handleZipChange,
    displayZipValidCSSCheck,
    validateZip,
    oncloseDrawer,
    selectedItem,
    birthDay,
    birthMonth,
    birthYear,
    hasAddons,
    displayAdditionalCompliance,
    open,
    setOpen,
    handleAddToCart,
    productBrand,
}) => {
    const wineContainerRef = useRef(null);
    const dispatch = useDispatch();
    const classes = useStyles();
    const wineValidation = useSelector(getWineData);
    const zipValid =  useSelector(getIsProductFilterZipcodeValid);
    const allFieldsValid = !dayErrorMessage && !monthErrorMessage && !yearErrorMessage;
    const getAvailability = () => {
        let availability = false;
        if (!wineValidation?.wineAvailability) { return false; }
        const keys = Object.keys(wineValidation.wineAvailability);
        keys.forEach((key) => {
            if (wineValidation.wineAvailability[key]?.isProductAvailable && allFieldsValid && zipValid && zipField.length === 5) {
                availability = true;
            }
        });
        return availability;
    };
    const brand = useSelector(getBrand);
    const [triggerTastingRestrictionsModal, setTriggerTastingRestrictionsModal]  = useState(false);
    const  handleTastingRestrictionsModal = (flag) => {
        setTriggerTastingRestrictionsModal(flag);
    };
    const [contentActive, setContentActive] = useState(true);
    const handleChangePanel = (panel) => (event, newExpanded) => {
        setContentActive((newExpanded) ? panel : null);
    };
    useEffect(() => () => { dispatch(resetWineData()); }, []);

    return (
        <>
            <Dialog
                PaperProps={{
                    className: hasAddons ? classes.addonsVerificationMobile : classes.addonsVerification,
                }}
                open={open}
                maxWidth="xs"
                onClose={() => { setOpen(false); }}
            >
                <DialogTitle className={classes.dialogHeader}>
                    <span className={classes.headerContent}>AGE CONFIRMATION</span>
                    <span role="presentation" onClick={() => { setOpen(false); }} className={classes.icon}>
                        <CloseIcon  />
                    </span>
                </DialogTitle>
                <DialogContent data-testid="ageVerificationForm-modal-content" className={classes.dialogContent} ref={wineContainerRef}>
                    <AgeVerificationForm
                        isModal
                        hasAddons={hasAddons}
                        oncloseDrawer={oncloseDrawer}
                        getBirthMonth={getBirthMonth}
                        validateFields={validateFields}
                        birthMonth={birthMonth}
                        monthErrorMessage={monthErrorMessage}
                        getBirthDay={getBirthDay}
                        birthDay={birthDay}
                        dayErrorMessage={dayErrorMessage}
                        getBirthYear={getBirthYear}
                        birthYear={birthYear}
                        yearErrorMessage={yearErrorMessage}
                        handleAgeVerification={handleAgeVerification}
                        handleZipChange={handleZipChange}
                        validateZip={validateZip}
                        zipField={zipField}
                        zipFieldRef={zipFieldRef}
                        displayZipValidCSSCheck={displayZipValidCSSCheck}
                        wineContainerRef={wineContainerRef}
                        displayAdditionalCompliance={displayAdditionalCompliance}
                        selectedItem={selectedItem}
                        classes={classes}
                        inlinePDPAddon
                    />
                    <div className={classes.buttonContainer}>
                        <Button
                            className={`${classes.submitButton} ${classes.GoBackBtn}`}
                            disabled={!getAvailability()}
                            onClick={() => {
                                setOpen(false);
                                handleAddToCart();
                            }}
                            data-testId="submit"
                        >
                            submit
                        </Button>
                        <Button className={classes.GoBackBtn} onClick={() => { setOpen(false); }} data-testId="goBack">
                            Cancel
                        </Button>
                    </div>
                    <ExpansionPanel classes={{ root: classes.panelRoot }} square expanded={contentActive} onChange={handleChangePanel(index)}>
                        <ExpansionPanelSummary classes={{ root: classes.summaryRoot, content: classes.summaryContent }}>
                            <Typography
                                component="span"
                                variant="h6"
                                className={classes.label}
                            >
                                <div className={classes.container}>
                                    <div className={classes.header}>Wine purchase Info</div>
                                </div>
                                <span className={classes.icons}>{(contentActive) ? <RemoveIcon className={classes.removeIcon} /> : <AddIcon />}</span>
                            </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails classes={{ root: classes.detailsRoot }}>
                            <GraphqlWineCompliance brand={productBrand ? { 'domain-name': productBrand } : brand} handleTastingRestrictionsModal={handleTastingRestrictionsModal} />
                        </ExpansionPanelDetails>
                    </ExpansionPanel>

                </DialogContent>
            </Dialog>
            <Dialog
                PaperProps={{
                    className: classes.addonsVerification,
                }}
                open={triggerTastingRestrictionsModal}
                maxWidth="xs"
                onClose={() => { setTriggerTastingRestrictionsModal(false); }}
            >
                <DialogTitle className={classes.dialogHeader}>
                    <span className={classes.headerContent}>WINE SHIPPING DETAILS</span>
                    <span role="presentation" onClick={() => { setTriggerTastingRestrictionsModal(false); }} className={classes.closeBox}>
                        <CloseIcon />
                    </span>
                </DialogTitle>
                <DialogContent data-testid="tasting-restrictions-modal-content" className={classes.dialogContent}>
                    <div className={classes.wineContainer} data-testid="wine-shipping-details">
                        <GraphqlWineRestrictions productBrand={productBrand} path="/h/view/wineship" />
                    </div>
                </DialogContent>
            </Dialog>
        </>

    );
};

InLineAddonVerification.defaultProps = {
    hasAddons: false,
    displayAdditionalCompliance: false,
    open: false,
    handleAddToCart: () => {},
    productBrand: '',
    oncloseDrawer: () => {},
};

InLineAddonVerification.propTypes = {
    zipField: string.isRequired,
    birthDay: string.isRequired,
    birthMonth: string.isRequired,
    birthYear: string.isRequired,
    dayErrorMessage: string.isRequired,
    monthErrorMessage: string.isRequired,
    yearErrorMessage: string.isRequired,
    zipFieldRef: func.isRequired,
    getBirthDay: func.isRequired,
    getBirthMonth: func.isRequired,
    getBirthYear: func.isRequired,
    handleAgeVerification: func.isRequired,
    validateFields: func.isRequired,
    handleZipChange: func.isRequired,
    displayZipValidCSSCheck: func.isRequired,
    validateZip: func.isRequired,
    oncloseDrawer: func,
    selectedItem: shape({
        id: string.isRequired,
    }).isRequired,
    open: bool,
    setOpen: func.isRequired,
    hasAddons: bool,
    displayAdditionalCompliance: bool,
    handleAddToCart: func,
    productBrand: string,
};

export default InLineAddonVerification;
