/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useRef, useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Grid, TextField, Input,
} from '@material-ui/core';
import IconClose from '@material-ui/icons/Close';
import {
    object, func, string, bool, shape,
} from 'prop-types';
import GraphqlWineRestrictions from './GraphqlWineRestrictions';
import { brandByCode } from '../../../../../../pages/Checkout/containers/CheckoutFood/components/Partials/Helper/Helper';
import GraphqlWineCompliance from '../../GraphqlAddon/Partials/GraphqlWineCompliance';

const styles = (theme) => ({
    mobileModal: {
        padding: '20px',
        '& .top-content': {
            textAlign: 'center',
        },
        '& .verification-container': {
            margin: '0 auto',
            maxWidth: 240,
        },
        '& .input-field': {
            minHeight: 85,
        },
        '& .zip-code-label': {
            margin: 0,
        },
        '& .zip-code-box': {
            paddingTop: 15,
        },
        '& .age-verification::after': {
            left: 30,
            top: 0,
        },
        '& .age-verification.success::after': {
            padding: 5,
        },
        '& .age-verification.success::before': {
            top: -15,
        },
        '& .zip-code-success::after': {
            right: 15,
        },
        '& .zip-code-success': {
            '&::before': {
                top: -30,
                right: 75,
            },
        },
        '& .zip-code-error': {
            '&::after': {
                top: -7,
                right: 30,
            },
        },
        '& p': {
            margin: '10px',
        },
    },
    addonBox: {
        '& .age-verification': {
            position: 'relative',
        },
        '& .age-verification::after': {
            left: 28,
            top: -10,
        },
        '& .zip-code-box': {
            paddingTop: 0,
        },
    },
    icon: {
        color: theme.palette.colorIconHeader,
        height: '40px',
        textAlign: 'right',
    },
    topContent: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    main: {
        width: '633px',
        height: '230px',
        margin: '0px 0px 18px 0px',
    },
    cardTitleZipCode: {
        fontSize: '16px',
        fontWeight: '700',
        margin: '31px 0px 8px 0px',
    },
    cardTitleBirth: {
        fontSize: '16px',
        fontWeight: '700',
        margin: '15px 0px 8px 0px',
    },
    textField: {
        minHeight: '45px',
        margin: '0px 0px 0px 15px',
        '& .MuiFormHelperText-root': {
            fontSize: '9px',
        },
        '& .MuiInputBase-root': {
            display: 'block',
        },
        '& .MuiInputBase-input': {
            padding: '9px 0',
            textAlign: 'center',
        },
    },
    verificationContainer: {
        display: 'block',
    },
    zipcode: {
        width: '100%',
        paddingTop: '40px',
        '& .MuiInputBase-input': {
            width: '68px',
            height: '29px',
            border: `1px solid ${theme.palette.cms?.wineVerifyDisable || '#b9b8b8'}`,
            backgroundColor: theme.palette.cms?.white || '#ffffff',
            '&:disabled': {
                backgroundColor: theme.palette.cms?.wineVerifyDisable || '#b9b8b8',
            },
        },
        '& .zip-code-error': {
            '&::before': {
                top: 0,
            },
        },
    },
    birth: {
        width: '250px',
        justifyContent: 'center',
    },
    submit: {
        color: theme.palette.white,
        backgroundColor: theme.palette.black || '#1f1f1b',
        fontFamily: theme.typography.fontFamily,
        boxSizing: 'border-box',
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
        width: 100,
        margin: '-75px 0px 0px 80px',
        '&:disabled': {
            backgroundColor: theme.palette.cms?.wineVerifyDisable || '#b9b8b8',
            color: theme.palette.grey8,
        },
        '&:hover': {
            backgroundColor: theme.palette.black || '#1f1f1b',
        },

        borderRadius: 24,
        height: 40,
        fontSize: 16,
        fontWeight: 'bold',
    },
    form: {
        width: '150px',
        margin: '8px 0px 0px 0px',
    },
    enterBirthInput: {
        width: '60px',
        height: '40px',
        border: `1px solid ${theme.palette.grey8}`,
        fontSize: '16px',
        marginTop: '3px',
        backgroundColor: theme.palette.cms?.white || '#ffffff',
        '& .MuiInput-underline:before, .MuiInput-underline:after': {
            width: '59px',
        },
        '& .MuiFormHelperText-root': {
            width: '65px',
            margin: '5px 0px',
        },
    },
    enterBirthYearInput: {
        width: '60px',
        height: '38px',
        border: `1px solid ${theme.palette.grey8}`,
        fontSize: '16px',
        marginTop: '3px',
        backgroundColor: theme.palette.cms?.white || '#ffffff',
        '& .MuiFormHelperText-root': {
            margin: '5px 0px',
            width: '85px',
        },
    },
    ageFieldsInput: {
        margin: '2px 2px 0px -12px',
    },
    ageVerificationSummary: {
        marginTop: '-13px',
    },
    zipField: {
        textAlign: 'center',
    },
    additionalCompliance: {
        backgroundColor: theme.palette.cms?.additionalComplianceColor || '#f9f6f6',
        color: theme.palette.black || '#1f1f1b',
        padding: '5px',
        fontSize: '0.8em',
        lineHeight: '1.4em',
        '& p': {
            margin: '10px',
        },
    },
    complianceLink: {
        color: theme.palette.black || '#1f1f1b',
        background: 'transparent',
        border: '0',
        padding: '0',
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    dialogMain: {
        position: 'relative',
    },
    wineContainer: {
        padding: '0 10px',
        fontSize: '0.8em',
        minHeight: '120px',
        minWidth: '300px',
        '& h4': {
            fontSize: '1.2em',
        },
        '& h5': {
            fontSize: '1em',
        },
        '& h6': {
            fontSize: '1em',
        },
    },
    wineHeader: {
        display: 'inline-block',
    },
    wineHeaderClose: {
        display: 'inline-block',
        right: '10px',
        top: '3px',
        position: 'absolute',
        backgroundColor: theme.palette.white || '#ffffff',
        cursor: 'pointer',
    },
});

const AgeVerificationForm = ({
    classes,
    oncloseDrawer,
    getBirthMonth,
    validateFields,
    birthMonth,
    monthErrorMessage,
    getBirthDay,
    birthDay,
    dayErrorMessage,
    getBirthYear,
    birthYear,
    yearErrorMessage,
    handleAgeVerification,
    handleZipChange,
    zipField,
    zipFieldRef,
    displayZipValidCSSCheck,
    isModal,
    wineContainerRef,
    displayAdditionalCompliance,
    isDrawer,
    selectedItem,
    inlinePDPAddon,
}) => {
    const yearFieldRef = useRef(null);
    const monthFieldRef = useRef(null);
    const dayFieldRef = useRef(null);
    const [triggerRestrictions, showWineShippingDetails] = useState(false);
    const numericOnly = /^[0-9]+$/;
    useEffect(() => {
        if (wineContainerRef.current && wineContainerRef.current.scrollIntoView) {
            wineContainerRef.current.scrollIntoView({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }
    }, [triggerRestrictions]);

    return (
        (isModal || isDrawer) && !triggerRestrictions ? (
            <>
                <div className={isModal ? classes.mobileModal : classes.addonBox}>
                    <div className={`top-content ${classes.topContent}`}>
                        <h3>Confirm Your Age and Destination For Wine Purchase</h3>
                        {!isModal && (
                            <div
                                className={classes.iconHeader}
                                onClick={() => oncloseDrawer(false)}
                                onKeyDown={() => { }}
                                role="button"
                                tabIndex={-1}
                            >
                                <IconClose className={classes.icon} />
                            </div>
                        )}
                    </div>
                    <p className={classes.ageVerificationSummary}>
                        I swear or affirm that: a) I am 21 years of age or older; b) my purchase of wine products is intended for personal consumption and not for resale; and c) the person receiving wine products is 21 years of age or older and will provide proof of age with valid identification and signature upon delivery.
                    </p>
                    <div className={`verification-container ${classes.verificationContainer}`} data-testid="age-verification-model">
                        <div className={classes.birth}>
                            <p className={classes.cardTitleBirth}>
                                Enter Your Date Of Birth
                            </p>
                            <Grid container direction="row" className={classes.ageFieldsInput}>
                                <div className={`input-field ${classes.textField}`}>
                                    <div className={classes.label}>Month</div>
                                    <TextField
                                        inputRef={monthFieldRef}
                                        margin="dense"
                                        id="month"
                                        type="tel"
                                        placeholder="mm"
                                        onChange={(e) => {
                                            getBirthMonth(e);
                                            if (e.target.value.length === 2 && e.target.value <= 12) {
                                                if (birthDay.length === 2 && birthDay <= 31) {
                                                    yearFieldRef.current.focus();
                                                    return;
                                                }
                                                dayFieldRef.current.focus();
                                            }
                                        }}
                                        className={classes.enterBirthInput}
                                        onBlur={(e) => {
                                            validateFields({ target: { value: !numericOnly.test(e.target.value) ? birthMonth : e.target.value, id: 'month' } });
                                        }}
                                        onKeyUp={(e) => {
                                            validateFields({ target: { value: !numericOnly.test(e.target.value) ? birthMonth : e.target.value, id: 'month' } }, zipFieldRef);
                                        }}
                                        value={birthMonth}
                                        inputProps={{
                                            'data-testid': 'month-field',
                                            maxLength: 2,
                                            pattern: '[0-9]/g',
                                            shrink: true,
                                            min: 1,
                                            max: 12,
                                        }}
                                        error={monthErrorMessage !== ''}
                                        helperText={!inlinePDPAddon && monthErrorMessage}
                                    />
                                </div>
                                <div className={`input-field ${classes.textField}`}>
                                    <div className={classes.label}>Day</div>
                                    <TextField
                                        margin="dense"
                                        id="day"
                                        inputRef={dayFieldRef}
                                        type="tel"
                                        placeholder="dd"
                                        onChange={(e) => {
                                            getBirthDay(e);
                                            if (e.target.value.length === 2 && e.target.value <= 31) {
                                                yearFieldRef.current.focus();
                                            }
                                        }}
                                        className={classes.enterBirthInput}
                                        onBlur={(e) => {
                                            validateFields({ target: { value: !numericOnly.test(e.target.value) ? birthDay : e.target.value, id: 'day' } });
                                        }}
                                        onKeyUp={(e) => {
                                            validateFields({ target: { value: !numericOnly.test(e.target.value) ? birthDay : e.target.value, id: 'day' } }, zipFieldRef);
                                        }}
                                        value={birthDay}
                                        inputProps={{
                                            'data-testid': 'day-field',
                                            maxLength: 2,
                                            pattern: '[0-9]/g',
                                            shrink: true,
                                            min: 1,
                                            max: 31,
                                        }}
                                        error={dayErrorMessage !== ''}
                                        helperText={!inlinePDPAddon && dayErrorMessage}
                                    />
                                </div>
                                <div className={`input-field ${classes.textField}`}>
                                    <div className={classes.label}>Year</div>
                                    <TextField
                                        inputRef={yearFieldRef}
                                        margin="dense"
                                        id="year"
                                        label=""
                                        type="tel"
                                        placeholder="yyyy"
                                        className={classes.enterBirthYearInput}
                                        onKeyUp={(event) => {
                                            validateFields(event, zipFieldRef);
                                        }}
                                        onChange={(event) => {
                                            validateFields({ target: { value: !numericOnly.test(event.target.value) ? birthYear : event.target.value, id: 'year' } }, zipFieldRef);
                                            getBirthYear(event);
                                        }}
                                        value={birthYear}
                                        inputProps={{
                                            maxLength: 4,
                                            'data-testid': 'year-field',
                                            pattern: '[0-9]/g',
                                            shrink: true,
                                        }}
                                        error={yearErrorMessage !== ''}
                                        helperText={!inlinePDPAddon && yearErrorMessage}
                                    />
                                </div>
                                <div disabled={!handleAgeVerification().flag} className={`age-verification ${handleAgeVerification().class}`} />
                            </Grid>
                        </div>
                        <div className={`zip-code-box ${classes.zipcode}`}>
                            <p className={`zip-code-label ${classes.cardTitleZipCode}`}>
                                Enter Destination ZIP code
                            </p>
                            <div className={classes.form}>
                                <div className={classes.label}>Zip Code</div>
                                <Input
                                    className={classes.zipField}
                                    disabled={!handleAgeVerification().flag}
                                    type="tel"
                                    margin="normal"
                                    id="zipcode-text-field"
                                    data-test="pdp-zipcode-text-field"
                                    name="zipcodeTextField"
                                    onChange={handleZipChange}
                                    // defaultValue={userSubmittedZip}
                                    value={zipField}
                                    inputRef={zipFieldRef}
                                    inputProps={{
                                        'data-testid': 'zip-code-field',
                                        maxLength: 5,
                                        className: classes.zipField,
                                    }}
                                />
                                {handleAgeVerification().flag && <div className={displayZipValidCSSCheck().classes} />}
                            </div>
                        </div>
                    </div>
                </div>
                {isModal && displayAdditionalCompliance && (
                    <div className={classes.additionalCompliance} data-testid="age-verification-additional-compliance">

                        <GraphqlWineCompliance brand={{ 'domain-name': brandByCode?.[selectedItem.brandId] || '' }} handleTastingRestrictionsModal={showWineShippingDetails} />
                    </div>
                )}
            </>
        ) : (
            <div className={classes.wineContainer} data-testid="wine-shipping-details">
                <h4 className={classes.wineHeader}>WINE SHIPPING DETAILS</h4>
                <div className={classes.wineHeaderClose} role="presentation" onClick={() => { showWineShippingDetails(false); }}>
                    <IconClose className={classes.icon} />
                </div>
                <GraphqlWineRestrictions brandId={selectedItem.brandId} path="/h/view/wineship" />
            </div>
        )
    );
};

AgeVerificationForm.defaultProps = {
    isModal: false,
    isDrawer: false,
    wineContainerRef: {},
    displayAdditionalCompliance: false,
    inlinePDPAddon: false,
};
AgeVerificationForm.propTypes = {
    classes: object.isRequired,
    selectedItem: shape({
        brandId: string.isRequired,
    }).isRequired,
    zipField: string.isRequired,
    birthDay: string.isRequired,
    birthMonth: string.isRequired,
    birthYear: string.isRequired,
    dayErrorMessage: string.isRequired,
    monthErrorMessage: string.isRequired,
    yearErrorMessage: string.isRequired,
    zipFieldRef: func.isRequired,
    getBirthDay: func.isRequired,
    getBirthMonth: func.isRequired,
    getBirthYear: func.isRequired,
    handleAgeVerification: func.isRequired,
    validateFields: func.isRequired,
    handleZipChange: func.isRequired,
    displayZipValidCSSCheck: func.isRequired,
    oncloseDrawer: func.isRequired,
    displayAdditionalCompliance: bool,
    isModal: bool,
    isDrawer: bool,
    inlinePDPAddon: bool,
    wineContainerRef: object,
};

export default withStyles(styles)(AgeVerificationForm);
