/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';
import { object, func, string } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ReactMarkdown from 'react-markdown';

const styles = (theme) => ({
    complianceLink: {
        background: 'none',
        border: 'none',
        textDecoration: 'underline',
        cursor: 'pointer',
        display: 'inline',
        fontSize: 'inherit',
        color: theme.palette.text?.secondary,
        padding: 0,
        '&:focus': {
            outline: 'none',
        },
        '&:hover': {
            color: theme.palette.black,
        },
    },
});

const WineComplianceBlock = ({ data, classes, handleTastingRestrictionsModal }) => {
    const codeBlock = (prop) => (
        <button
            type="button"
            className={classes.complianceLink}
            data-testid="addon-item-wine-restrictions"
            onClick={() => { handleTastingRestrictionsModal(true); }}
        >
            {prop.children}
        </button>
    );
    return (
        <ReactMarkdown source={data} renderers={{ inlineCode: codeBlock }} escapeHtml={false} />
    );
};

WineComplianceBlock.propTypes = {
    classes: object.isRequired,
    data: string.isRequired,
    handleTastingRestrictionsModal: func.isRequired,
};

export default withStyles(styles)(WineComplianceBlock);
