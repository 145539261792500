/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { useQuery } from '@apollo/client';
import { shape, string, func } from 'prop-types';
import mbpLogger from 'mbp-logger';
import { GRAPHQL_ENV } from '../../../../../../gql';
import findContent from '../../../../../../gql/queries/findContent';

import WineComplianceBlock from './WineComplianceBlock';

const GraphqlWineCompliance = ({ brand, handleTastingRestrictionsModal }) => {
    const findContentOptions = {
        brand: brand['domain-name'],
        contentType: 'brand_configuration',
    };

    const GET_WINE_COMPLIANCE_INFORMATION = findContent(findContentOptions);

    const { data, loading, error } = useQuery(GET_WINE_COMPLIANCE_INFORMATION);

    if (loading) {
        return null;
    }
    if (error) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            query: GET_WINE_COMPLIANCE_INFORMATION,
            component: ' GraphqlWineCompliance.js',
            message: 'Error loading data from Graphql',
            env: GRAPHQL_ENV,
            error,
        });
        return null;
    }
    if (!data || !data.findContent?.content?.entries) {
        mbpLogger.logWarning({
            appName: process.env.npm_package_name,
            query: GET_WINE_COMPLIANCE_INFORMATION,
            component: 'GraphqlWineCompliance.js',
            message: 'No data returned for query',
            env: GRAPHQL_ENV,
        });
        return null;
    }

    const wineComplianceCopy = data.findContent?.content?.entries?.[0]?.other.wine_compliance_copy || null;

    return wineComplianceCopy ? <WineComplianceBlock data={wineComplianceCopy} handleTastingRestrictionsModal={handleTastingRestrictionsModal} /> : <></>;
};

GraphqlWineCompliance.propTypes = {
    brand: shape({
        domain: string,
    }).isRequired,
    handleTastingRestrictionsModal: func.isRequired,
};

export default GraphqlWineCompliance;
