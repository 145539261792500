/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState } from 'react';
import {
    object, string, bool, shape, func,
} from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import IconButton from '@material-ui/core/IconButton';
import * as deleteItemActions from '../../../../../../../../state/ducks/Cart/ducks/DeleteItem/DeleteItem-Actions';
import ModalWrapper from '../../../../../../GraphqlComponents/GraphqlHeader/ModalWrapper/ModalWrapper';
import { getLoaderStatus } from '../../../../../../../../state/ducks/Common/Common-Selectors';
import SiteLoader from '../../../../../../../pages/Checkout/containers/Common/SiteLoader/SiteLoader';

const styles = (theme) => ({
    modalContainer: {
        padding: '0',
        '&:focus': {
            outline: 'none',
        },
    },
    headerModalText: {
        textAlign: 'center',
        fontWeight: '700',
        marginBottom: '15px',
    },
    btnContinue: {
        backgroundColor: theme.palette.primaryButton,
        color: theme.palette.colorPrimaryContrast,
        fontSize: '14px',
        fontWeight: 'bold',
        margin: '10px auto',
        display: 'block',
        width: '220px',
        '&:hover, &:focus, &:active': {
            backgroundColor: theme.palette.primaryButton,
        },
    },
    btnRemove: {
        backgroundColor: theme.palette.cms?.primary || theme.palette.colorPrimary,
        color: theme.palette.colorPrimaryContrast,
        fontSize: '14px',
        fontWeight: 'bold',
        margin: '10px auto',
        display: 'block',
        width: '220px',
        '&:hover, &:focus, &:active': {
            backgroundColor: theme.palette.cms?.primary || theme.palette.colorPrimary,
        },
    },
    remove: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '5px 0px',
        width: '100%',
        '&:hover': {
            background: 'transparent',
        },
    },
});

const DeleteItemButton = ({
    classes, orderItemId, pageProcess, callDeleteItemActions, showLoadingMessage, parentSkuCode,
}) => {
    const [isOpen, setModal] = useState(false);
    const [isShowLoading, setLoading] =  useState(false);
    const openModal = () => {
        setModal(true);
    };
    const closeModal = () => {
        setModal(false);
    };
    const handleKeyPress = (event) => {
        if (event?.code === 13) {
            openModal();
        }
    };

    const handleRemoveItem = () => {
        callDeleteItemActions.deleteMiniCartItem(orderItemId, pageProcess, parentSkuCode, showLoadingMessage);
        closeModal(false);
        setLoading(true);
    };
    const getModalContent = () => (
        <div className={classes.modalContainer}>
            <div className={classes.headerModalText}>Remove item from cart?</div>
            <Button
                color="primary"
                id="btn-continue-mini-cart"
                data-test="cart-btn-continue-mini-cart"
                name="continueButton"
                className={classes.btnContinue}
                aria-label="NO, CONTINUE CHECKOUT"
                onClick={closeModal}
            >
                KEEP ITEM
            </Button>
            <Button
                color="secondary"
                id="btn-confirm-remove-mini-cart"
                data-test="btn-confirm-remove-mini-cart"
                data-testid="btn-remove-mini-cart"
                name="confirmRemoveItemButton"
                className={classes.btnRemove}
                aria-label="YES, REMOVE ITEM"
                onClick={() => handleRemoveItem()}
            >
                YES, REMOVE ITEM
            </Button>
        </div>
    );
    return (
        <>
            {(showLoadingMessage && isShowLoading)
                    && (
                        <SiteLoader />
                    )}
            <IconButton
                className={classes.remove}
                aria-label="remove"
                data-testid="checkout-item-remove-mini-link"
                onClick={() => openModal()}
                onKeyPress={(e) => handleKeyPress(e)}
            >
                <img src="https://images.contentstack.io/v3/assets/blt89dbf1c763ec00a6/blt7d959b106e53b5a8/6227453b86e1c60d0c62e8a7/mini-delete.png"  alt="Remove Logo" />
            </IconButton>
            {isOpen &&  (
                <ModalWrapper
                    modalTitle="REMOVE ITEM"
                    modalContent={getModalContent()}
                    isOpen={isOpen}
                    onCloseModal={() => setModal(false)}
                    btnClose={false}
                />
            ) }

        </>
    );
};
DeleteItemButton.propTypes = {
    classes: object.isRequired,
    orderItemId: string.isRequired,
    pageProcess: string,
    parentSkuCode: string.isRequired,
    callDeleteItemActions: shape({
        deleteItemFood: func.isRequired,
    }).isRequired,
    showLoadingMessage: bool,
};

DeleteItemButton.defaultProps = {
    pageProcess: '',
    showLoadingMessage: false,
};

const mapStateToProps = (state) => ({
    showLoadingMessage: getLoaderStatus(state),
});

const mapDispatcherToProps = (dispatch) => ({
    callDeleteItemActions: bindActionCreators(deleteItemActions, dispatch),
});

const enhance = compose(
    connect(mapStateToProps, mapDispatcherToProps),
    withRouter,
    withStyles(styles),
);

export default enhance(DeleteItemButton);
