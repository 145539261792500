/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    continueShoppingButton: {
        border: `1px ${theme.palette.primaryButton} solid`,
        backgroundColor: `${theme.palette.primaryButton}`,
        borderRadius: '10px',
        margin: '1em',
        height: '2.5em',
        width: '70%',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '@media only screen and (max-width: 320px)': {
            width: '80%',
        },
        color: theme.palette.white,
        textDecoration: 'none',
    },
}));

const ContinueShoppingButton = () => {
    const classes = useStyles();
    return (
        <Grid align="center" item xl>
            <Link className={classes.continueShoppingButton} to="/">Continue Shopping</Link>
        </Grid>
    );
};
export default ContinueShoppingButton;
