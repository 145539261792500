/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { gql } from '@apollo/client';
import { GRAPHQL_ENV } from '..';

/**
 * @param {string} brand - domain name: brand['domain-name']
 * @param {string} path - path to be fetched: page.path
 */

const findContentPage = (brand, path) => (
    gql`{ 
            findContentPage(brand: "${brand}", environment: "${GRAPHQL_ENV}",  locale: "en-us", contentType: "content_page", url: "${path}"){
            content
        }}`
);

export default findContentPage;
