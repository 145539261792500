/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const LinkBlockStyle = ({ palette }) => ({
    boxLayoutBorder: {
        border: `1px solid ${(palette.text?.disabled || '#B7B7B7')}`,
        padding: '5px 14px 8px 14px',
        marginBottom: '10px',
    },
});

export default LinkBlockStyle;
