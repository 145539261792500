/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React, { useState } from 'react';
import {
    object,
} from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ReactHtmlParser from 'react-html-parser';

const useStyles = makeStyles((theme) => ({
    flavorsSelected: {
        fontSize: '14px',
        fontWeight: '400',
        color: theme.palette?.primary?.main,
        borderTop: '1px dotted rgba(213, 213, 213, 1)',
        paddingTop: '8px',
    },
    flavorsSelectedName: {
        fontSize: '12px',
        fontWeight: '400',
        color: theme.palette?.primary?.main,
        paddingTop: '8px',
    },
    expandIcon: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingBottom: '8px',
    },
    flavorsSelectedQty: {
        fontSize: '12px',
        fontWeight: '400',
        color: theme.palette?.primary?.main,
        paddingTop: '8px',
        justifyContent: 'flex-end',
        display: 'flex',
    },
}));

const AddonSummary = ({
    itemDetails,
}) => {
    const classes = useStyles();
    const [isOpen, setModal] = useState(true);

    if (!itemDetails || !itemDetails.addons || itemDetails.addons.length === 0) { return null; }

    const pdpGCIPersonalization = (itemDetails.addons).filter((addon) => (addon.itemInfo.lineItemType === 'PERSONALIZATION'));
    if (itemDetails.addons.length === 1 && pdpGCIPersonalization?.length > 0) { return null; }

    return (
        <Grid container>
            <Grid item xs={12} className={classes.expandIcon}>{isOpen ? <ExpandLess data-testid="expand-less-icon" onClick={() => setModal(false)} /> : <ExpandMore data-testid="expand-more-icon" onClick={() => setModal(true)} />}</Grid>
            {isOpen && (
                <>
                    <Grid xs={12} item className={classes.flavorsSelected}>
                        Addons you selected:
                    </Grid>
                    {itemDetails.addons.map((selectedFlavors, index) => {
                        if (selectedFlavors.itemInfo?.lineItemType !== 'PERSONALIZATION') {
                            const keyFlavourName = `addon_flavour_${selectedFlavors.product?.productCode}_${index}`;
                            const keyFlavourQty = `addon_flavour_${selectedFlavors.product?.productCode}_${index}`;
                            return (
                                <>
                                    <Grid key={keyFlavourName} xs={11} className={classes.flavorsSelectedName}>
                                        {ReactHtmlParser(selectedFlavors.product?.parentProductName || '')}
                                    </Grid>
                                    <Grid key={keyFlavourQty} xs={1} className={classes.flavorsSelectedQty}>
                                        {selectedFlavors.itemInfo?.quantity || '0'}
                                    </Grid>
                                </>
                            );
                        }
                        return null;
                    })}
                </>
            )}
        </Grid>
    );
};

AddonSummary.propTypes = {
    itemDetails: object, // dynamic with sku number
};
AddonSummary.defaultProps = {
    itemDetails: {},
};

export default (AddonSummary);
