/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const getSkuPriceRange = (skuPriceRange) => {
    const { sale, retail } = skuPriceRange;
    let retailPrice;
    let salePrice;

    if (retail?.length > 1 && sale?.length > 1) {
        if (
            sale[0].value.toString() !== retail[0].value?.toString()
            || sale[1].value.toString() !== retail[1].value?.toString()
        ) {
            salePrice = sale[0].value === sale[1].value
                ? `$${sale[0].value}`
                : `$${sale[0].value} - $${sale[1].value}`;
        }
        retailPrice = retail[0].value === retail[1].value
            ? `$${retail[0].value}`
            : `$${retail[0].value} - $${retail[1].value}`;
    }
    return {
        retailPrice,
        salePrice,
    };
};

export default getSkuPriceRange;
