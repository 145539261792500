/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import ReactMarkdown from 'react-markdown';
import Slider from 'react-slick';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {
    string, bool, func, shape, object,
} from 'prop-types';

const styles = ((theme) => ({
    headerText: {
        color: theme.palette.cms?.primary || theme.palette.colorPrimary,
        fontWeight: 'bold',
        fontSize: '1.125rem',
        lineHeight: '1.25rem',
    },
    solidDividerLine: {
        width: '100%',
        height: 60,

        [theme.breakpoints.down('md')]: {
            backgroundColor: 'inherit',
        },
    },
    contentWrapper: {
        display: 'block',
        marginTop: '11%',
        maxWidth: '320px',
        '& img': {
            width: '100%',
        },
        '& p': {
            display: 'block',
            width: '100%',
            margin: 0,
            fontSize: '1rem',
            lineHeight: '1.25rem',
        },

    },
    closeIcon: {
        position: 'absolute',
        right: '3%',
        cursor: 'pointer',
        width: '0.8em',
        top: '0.75rem',
    },
    headerTextWrapper: {
        textAlign: 'center',
        width: '100%',
    },
    headerWrapper: {
        padding: '15px 0px',
        position: 'fixed',
        maxWidth: '320px',
        background: theme.palette.colorNeutral20 || '#f5f5f5',
        [theme.breakpoints.down(380)]: {
            width: '80%',
            display: 'flex',
        },
    },
    dialogHeader: {
        padding: 'inherit',
    },
    sliderContainer: {
        position: 'relative',
        maxWidth: '1400px',
        margin: 'auto',
        '& .slick-list': {
            overflow: 'hidden',
            margin: '0 auto',
        },
        '& .slick-slide': {
            display: 'inline-block',
            padding: 3,
            verticalAlign: 'top',
            width: '100% !important',
        },
        '& .slick-track': {
            padding: '0px 5%',
        },
        '& .slick-prev, & .slick-next': {
            transform: 'rotate(90deg)',
            cursor: 'pointer',
            border: 'none',
            outline: 0,
            width: '20px',
            height: '20px',
            position: 'absolute',
        },
        '& .slick-prev': {
            top: '-20px',
            left: '47%',
            opacity: '0.9',
            zIndex: '1',
        },
        '& .slick-next': {
            bottom: '-30px',
            right: '48%',
            opacity: '0.9',
        },
        '& .slick-prev.slick-disabled': {
            color: theme.palette.cms?.white || '#ffffff',
        },
        '& .slick-next.slick-disabled': {
            color: theme.palette.cms?.lightGray || '#d6d6d6',
        },
    },
    wrapUpDialog: {
        '& .MuiDialog-paperWidthSm': {
            minHeight: '660px',
        },
    },
    imageContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        gap: '5px',
    },
    movieImage: {
        height: '85px',
    },
    dialogContainer: {
        '& $contentWrapper': {
            '& br': {
                content: "''",
                marginTop: '0.2em',
                display: 'block',
            },
            '& p': {
                padding: '0px 10px',
            },
        },
    },
}));

const WrapUpMovieModal = ({
    modalData, openModal, anchorGroupAddonsWrapup, classes, isSlideOpen,
}) => {
    const seeTitle = modalData?.nbcu_see_titles?.split('\n');
    return (
        <Dialog open={openModal} className={`${isSlideOpen ? classes.dialogContainer : ''} ${classes.wrapUpDialog}`}>
            <DialogTitle className={classes.dialogHeader}>
                <Grid container className={classes.headerWrapper}>
                    <Grid item className={classes.headerTextWrapper}>
                        <Typography className={classes.headerText}>
                            Digital Movie Selection
                        </Typography>
                    </Grid>
                    <Grid item className={classes.closeIconWrapper}>
                        <CloseIcon
                            data-testid="closeButton"
                            className={classes.closeIcon}
                            onClick={() => { anchorGroupAddonsWrapup(); }}
                        />
                    </Grid>
                </Grid>
                <div className={classes.solidDividerLine} />
                {modalData?.movie_images?.images.length > 0 && (
                    <div className={classes.imageContainer}>
                        {modalData?.movie_images?.images.map((item) => (
                            <img src={item?.url} alt={item?.title} className={classes.movieImage} key={item?.file_size} />
                        ))}
                    </div>
                )}
            </DialogTitle>
            <Grid container className={classes.contentWrapper}>
                <Slider
                    speed={500}
                    slidesToShow={17}
                    slidesToScroll={8}
                    nextArrow={<ArrowForwardIosIcon />}
                    prevArrow={<ArrowBackIosIcon />}
                    vertical
                    verticalSwiping
                    className={`carousel-container ${classes.sliderContainer}`}
                    infinite={false}
                >
                    {seeTitle?.map((value) => <ReactMarkdown source={value} key={value} className={classes.moreMovies} escapeHtml={false} />)}
                </Slider>
            </Grid>
        </Dialog>

    );
};
WrapUpMovieModal.propTypes = {
    modalData: shape({
        nbcu_see_titles: string.isRequired,
        info_on_hover: string.isRequired,
    }).isRequired,
    openModal: bool.isRequired,
    anchorGroupAddonsWrapup: func.isRequired,
    classes: object.isRequired,
    isSlideOpen: bool,
};
WrapUpMovieModal.defaultProps = {
    isSlideOpen: false,
};
export default withStyles(styles)(WrapUpMovieModal);
