/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';

import {  string, shape } from 'prop-types';
import SubscriptionSummary from './SubscriptionSummary';
import PersonalizationLineSummary from './PersonalizationLineSummary';
import CyoFlavorsSummary from './CyoFlavorsSummary';
import AddonSummary from './AddonSummary';

const NonStandardItem = ({ itemDetails }) => (
    <>
        <SubscriptionSummary subscriptionData={itemDetails?.subscription || {}} />
        <PersonalizationLineSummary itemDetails={itemDetails} />
        <CyoFlavorsSummary components={itemDetails?.components || []} />
        <AddonSummary itemDetails={itemDetails} />
    </>
);

NonStandardItem.propTypes = {
    itemDetails: shape({
        subscription: shape({
            duration: string.isRequired,
            interval: string.isRequired,
            type: string.isRequired,
        }),
    }),

};
NonStandardItem.defaultProps = {
    itemDetails: {},
};

export default NonStandardItem;
