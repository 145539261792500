/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';
import { uid } from 'react-uid';
import { getCartAttributeByNameAndReturnValue } from '../../../../../../../helpers/page/pageHelpers';

const skuBuilderWithDash = (sku) => {
    const endIndex = sku.lastIndexOf('-') + 1;
    return sku.slice(endIndex);
};

const skuBuilderWithUnderscore = (sku) => {
    const firstUnderscoreIndex = sku.indexOf('_');
    return sku.slice(firstUnderscoreIndex + 1, sku.length);
};

export const skuBuilder = (sku) => (sku.indexOf('-') !== -1 ? skuBuilderWithDash(sku) : skuBuilderWithUnderscore(sku));

export const formatGiftMessage = (data, styleClass) => {
    let message = '';
    if (data) {
        message = data.split('|').map((line, index) => (<span key={uid('giftMessage_', index)} className={styleClass}>{line}<br /></span>));
    }
    return message || 'No Card Message';
};

export const getNormalizeGiftMessageGCI = (orderItem) => {
    const greetingCards = orderItem.greetingCards || [];
    const cgclabelData = orderItem.attributes || {};
    let giftMessageGciData = {};
    const price = parseFloat(orderItem.itemInfo?.price || 0).toFixed(2);

    if (Object.keys(cgclabelData).length > 0) {
        // Label TPF Cards on PDP
        const cgcLabelThumbnail = getCartAttributeByNameAndReturnValue(cgclabelData, 'thumbnail');
        const cgcLabelKeyId = getCartAttributeByNameAndReturnValue(cgclabelData, 'key_id');

        giftMessageGciData = {
            data: {
                thumbnail: cgcLabelThumbnail || '',
                text: orderItem.greetingMessage?.greetingMessage || '',
                price: `$${price}`,
                gciItemId: orderItem.cartItemId || '',
                gciKeyId: cgcLabelKeyId || '',
            },
        };
    } else if (greetingCards?.length > 0) {
        // CGC or CI
        giftMessageGciData = {
            data: {
                thumbnail: greetingCards?.greetingCardImage || '',
                text: orderItem.greetingMessage?.greetingMessage || '',
                price: `$${price}`,
                gciItemId: orderItem.cartItemId || '',
                gciKeyId: greetingCards[0].greetingCardKey || '',
            },
        };
    }
    return giftMessageGciData;
};

export const getIfDateIsPastDate = (date) => {
    const objDate = new Date(date);
    const now = new Date().setHours(0, 0, 0, 0);
    if (objDate < now) {
        return true;
    }
    return false;
};

export const checkDateIsDeliverable = (deliveryDate) => {
    if (deliveryDate) {
        const isPastDate = getIfDateIsPastDate(deliveryDate);
        if (isPastDate) {
            return 'No';
        }
        return 'Yes';
    }
    return 'NoDate';
};

// Use for currency format for ex:
// 1000 => 1,000
// 80000 => 80,000
// 1000000 => 1,000,000
export const setCurrencyFormatforTotalOrder = (totalPrice) => {
    if (totalPrice) {
        return totalPrice.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
    }
    return null;
};

export const brandByCode = {
    1001: '1800flowers',
    1019: 'harryanddavid',
    1009: '1800baskets',
    1025: 'simplychocolate',
    1010: 'fruitbouquets',
    1012: 'cheryls',
    1022: 'personalizationuniverse',
    1002: 'thepopcornfactory',
    1020: 'wolfermans',
    1018: 'stockyards',
    1030: 'personalizationmall',
    1031: 'vitalchoice',
    1029: 'berries',
    1027: 'themarket',
    1033: 'thingsremembered',
    1034: 'florists',
};

export const getBrandIdByName = {
    '1800flowers': 1001,
    harryanddavid: 1019,
    '1800baskets': 1009,
    simplychocolate: 1025,
    fruitbouquets: 1010,
    cheryls: 1012,
    personalizationuniverse: 1022,
    thepopcornfactory: 1002,
    wolfermans: 1020,
    stockyards: 1018,
    personalizationmall: 1030,
    thingsremembered: 1033,
    vitalchoice: 1031,
    berries: 1029,
    florists: 1034,
};
export default {};
