/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { isEmpty } from './object';
import configENV from '../config';

const { PERSIST_KEY, SHOP_THE_SITE_KEY } = configENV;

const getShopTheSite = (contactId) => {
    let recipient;
    // eslint-disable-next-line space-unary-ops
    if (typeof(window) !== 'undefined' && contactId && SHOP_THE_SITE_KEY) {
        const accountStorage = sessionStorage.getItem(PERSIST_KEY);

        let storageJson = { [contactId]: {} };
        if (accountStorage) {
            storageJson = JSON.parse(accountStorage);
            if (
                storageJson
                && SHOP_THE_SITE_KEY
                && storageJson[contactId]
                && storageJson[contactId][SHOP_THE_SITE_KEY]
            ) {
                recipient = storageJson[contactId][SHOP_THE_SITE_KEY];
            }
        }
    }
    return recipient;
};

const setShopTheSiteLinkBackToGiftList = (contactId) => {
    // eslint-disable-next-line space-unary-ops
    if (typeof(window) !== 'undefined' && contactId && SHOP_THE_SITE_KEY) {
        const accountStorage = sessionStorage.getItem(PERSIST_KEY);
        let storageJson = { [contactId]: {} };
        if (accountStorage) {
            storageJson = JSON.parse(accountStorage);
        }
        const shopTheSite = storageJson[contactId][SHOP_THE_SITE_KEY];
        if (!isEmpty(shopTheSite)) {
            const {
                giftListURL,
                remainingRecipient,
                sourceAttributes,
            } = shopTheSite;
            storageJson[contactId][SHOP_THE_SITE_KEY] = {
                giftListURL,
                remainingRecipient,
                sourceAttributes,
            };
            storageJson[contactId].timestamp = new Date().getTime();
            sessionStorage.setItem(PERSIST_KEY, JSON.stringify(storageJson));
        }
    }
};

const resetShopTheSiteStorage = (contactId) => {
    // eslint-disable-next-line space-unary-ops
    if (typeof(window) !== 'undefined' && contactId && SHOP_THE_SITE_KEY) {
        const accountStorage = sessionStorage.getItem(PERSIST_KEY);
        let storageJson = { [contactId]: {} };
        if (accountStorage) {
            storageJson = JSON.parse(accountStorage);
        }
        if (
            storageJson
            && SHOP_THE_SITE_KEY
            && storageJson[contactId]
            && storageJson[contactId][SHOP_THE_SITE_KEY]
        ) {
            storageJson[contactId][SHOP_THE_SITE_KEY] = {};
            storageJson[contactId].timestamp = new Date().getTime();
            sessionStorage.setItem(PERSIST_KEY, JSON.stringify(storageJson));
        }
    }
};

const deleteShopTheSiteStorage = (contactId) => {
    // eslint-disable-next-line space-unary-ops
    if (typeof(window) !== 'undefined' && contactId && SHOP_THE_SITE_KEY) {
        const accountStorage = sessionStorage.getItem(PERSIST_KEY);
        let storageJson = { [contactId]: {} };
        if (accountStorage) {
            storageJson = JSON.parse(accountStorage);
            delete storageJson[contactId][SHOP_THE_SITE_KEY];
        }
        storageJson[contactId].timestamp = new Date().getTime();
        sessionStorage.setItem(PERSIST_KEY, JSON.stringify(storageJson));
    }
};

export {
    isEmpty,
    getShopTheSite,
    setShopTheSiteLinkBackToGiftList,
    resetShopTheSiteStorage,
    deleteShopTheSiteStorage,
};
