/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as actionTypes from './UpdateCart-ActionTypes';

export const updateOrder = (payload, updateOrderProps, callback) => ({
    type: actionTypes.UPDATE_ORDER,
    data: payload,
    updateOrderProps,
    callback,
});

export default {};
