/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Query } from '@apollo/client/react/components';
import mbpLogger from 'mbp-logger';
import { object, string } from 'prop-types';
import { compose } from 'recompose';
import { useUIDSeed } from 'react-uid';

import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import { getBrand } from '../../../../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import { GRAPHQL_ENV } from '../../../../../../gql';
import GraphqlPortalLoader from '../../../../GraphqlCommonComponents/GraphqlPortalLoader';

// import ContentPageBlock from '../../../../GraphqlContentPage/ContentPageBlock';
import TastingNotesBlock from '../../GraphqlAddon/Partials/TastingNotesBlock';
import findContentPage from '../../../../../../gql/queries/findContentPage';
import LinkBlock from '../../../../../ContentTypeComponents/LinkBlock/LinkBlock';
import { brandByCode } from '../../../../../../pages/Checkout/containers/CheckoutFood/components/Partials/Helper/Helper';

const GraphqlWineRestrictions = ({
    brand, path, brandId, productBrand,
}) => {
    const CONTENTPAGE_QUERY = findContentPage(productBrand || brandByCode?.[brandId] || brand['domain-name'], path);
    const seed = useUIDSeed();
    return (
        <Query query={CONTENTPAGE_QUERY}>
            {({ loading, error, data }) => {
                let response = null;
                if (loading) {
                    response = <div data-testId="graphql-portal-loader"><GraphqlPortalLoader /></div>;
                } else if (error) {
                    mbpLogger.logError({
                        appName: process.env.npm_package_name,
                        query: CONTENTPAGE_QUERY,
                        component: 'GraphqlWineRestrictions.js',
                        message: 'Error loading data from Graphql',
                        env: GRAPHQL_ENV,
                        error,
                    });

                    response = null;
                } else if (!data) {
                    mbpLogger.logWarning({
                        appName: process.env.npm_package_name,
                        query: CONTENTPAGE_QUERY,
                        component: 'GraphqlWineRestrictions.js',
                        message: 'No data returned for query',
                        env: GRAPHQL_ENV,
                    });

                    response = null;
                } else if (!data?.findContentPage?.content) {
                    mbpLogger.logWarning({
                        appName: process.env.npm_package_name,
                        query: CONTENTPAGE_QUERY,
                        component: 'GraphqlWineRestrictions.js',
                        message: 'No content returned for query',
                    });
                    response = null;
                } else if (data?.findContentPage?.content?.entries.length === 0) {
                    response = <h4>This content is not available right now. Please try again later.</h4>;
                } else {
                    const pageBlocks = data.findContentPage.content.entries?.[0] || null;

                    if (path === '/h/view/tasting-notes') {
                        response = (
                            pageBlocks?.center_blocks?.map((block, index) => (
                                <TastingNotesBlock data={block.tabular_data.table} key={seed(block, index)} />
                            ))
                        );
                    } else {
                        response =  (
                            pageBlocks?.center_blocks?.map((block, index) => {
                                const blockKey = Object.keys(block)[0];
                                const blockObj = block[blockKey];
                                switch (blockKey) {
                                    case 'image':
                                        if (blockObj?.link_img) {
                                            return (
                                                <Link key={seed(block, index)} to={blockObj.link_img?.href} title={blockObj.link_img?.title}>
                                                    <img src={blockObj.image?.url} alt={blockObj.image?.title} />
                                                </Link>
                                            );
                                        }
                                        return <img src={blockObj?.image?.url} alt={blockObj?.image?.title} />;

                                    case 'copy':
                                        return <div data-testId="copy_text"><ReactMarkdown key={seed(block, index)} source={blockObj.copy} escapeHtml={false} /></div>;

                                    case 'link_block':
                                        return <LinkBlock key={seed(block, index)} data={blockObj}  />;
                                    default:
                                        mbpLogger.logDebug({
                                            appName: process.env.npm_package_name,
                                            message: 'Unknown GraphqlWineRestrictions page block type in GraphqlWineRestrictions.js',
                                        });
                                        return null;
                                }
                            })
                        );
                    }
                }
                return response;
            }}
        </Query>
    );
};

GraphqlWineRestrictions.propTypes = {
    brand: object.isRequired,
    path: string.isRequired,
    brandId: string.isRequired,
    productBrand: string,
};

GraphqlWineRestrictions.defaultProps = {
    productBrand: '',
};

const mapStateToProps = (state) => ({
    brand: getBrand(state),
});

const enhance = compose(
    withRouter,
    connect(mapStateToProps),
);

export default enhance(GraphqlWineRestrictions);
