/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import ReactMarkdown from 'react-markdown';
import {
    string, bool, func, shape, object,
} from 'prop-types';
import { useSelector } from 'react-redux';
import { getFeatureFlag } from '../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import WrapUpMovieModal from './WrapUpMovieModal';

const styles = ((theme) => ({
    headerText: {
        color: theme.palette.cms?.primary || theme.palette.colorPrimary,
        fontWeight: 'bold',
    },
    solidDividerLine: {
        width: '100%',
        height: 41,
        [theme.breakpoints.down('md')]: {
            backgroundColor: 'inherit',
        },
    },
    contentWrapper: {
        display: 'block',
        maxWidth: '320px',
        '& img': {
            width: '100%',
        },
        '& p': {
            display: 'block',
            width: '100%',
        },
        '& ul': {
            display: 'block',
            listStyle: 'none',
            width: '100%',
        },
    },
    closeIcon: {
        position: 'absolute',
        right: '1%',
        cursor: 'pointer',
    },
    headerTextWrapper: {
        textAlign: 'center',
        width: '100%',
    },
    headerWrapper: {
        padding: '15px 0px 0px',
        position: 'fixed',
        maxWidth: '320px',
        background: theme.palette.cms?.white || '#fff',
        [theme.breakpoints.down(380)]: {
            width: '80%',
            display: 'flex',
        },
    },
    dialogHeader: {
        padding: 'inherit',
    },
    dialogContainer: {
        '& $contentWrapper': {
            '& br': {
                content: "''",
                marginTop: '0.2em',
                display: 'block',
            },
            '& p': {
                padding: '0px 10px',
            },
        },
    },
}));

const WrapUpModal = ({
    modalData, openModal, closeModal, classes, groupItemId, isSlideOpen,
}) => {
    const anchorGroupAddonsWrapup = () => {
        closeModal();

        if (typeof window !== 'undefined' && document.querySelector(`div.addonwrap-${groupItemId}`)) {
            const element = document.querySelector(`div.addonwrap-${groupItemId}`);
            // Scroll the window until the position of the product  is not filled yet
            if (element?.scrollIntoView) {
                setTimeout(() => {
                    element.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'nearest',
                    });
                }, 300);
            }
        }
    };
    const nbcuMovieModal = useSelector(getFeatureFlag('nbc_movie_modal'));
    if (nbcuMovieModal) {
        return (
            <WrapUpMovieModal modalData={modalData} openModal={openModal} anchorGroupAddonsWrapup={anchorGroupAddonsWrapup} isSlideOpen={isSlideOpen} />
        );
    }
    return (
        <Dialog open={openModal} className={isSlideOpen ? classes.dialogContainer : ''}>
            <DialogTitle className={classes.dialogHeader}>
                <Grid container className={classes.headerWrapper}>
                    <Grid item className={classes.headerTextWrapper}>
                        <Typography className={classes.headerText}>
                            Digital Movie Selection
                        </Typography>
                    </Grid>
                    <Grid item className={classes.closeIconWrapper}>
                        <CloseIcon
                            data-testid="closeButton"
                            className={classes.closeIcon}
                            onClick={() => { anchorGroupAddonsWrapup(); }}
                        />
                    </Grid>
                </Grid>
                <div className={classes.solidDividerLine} />
            </DialogTitle>
            <Grid container className={classes.contentWrapper}>
                <ReactMarkdown source={modalData?.nbcu_see_titles} className={classes.moreMovies} />
            </Grid>
        </Dialog>

    );
};
WrapUpModal.propTypes = {
    modalData: shape({
        nbcu_see_titles: string.isRequired,
        info_on_hover: string.isRequired,
    }).isRequired,
    openModal: bool.isRequired,
    closeModal: func.isRequired,
    classes: object.isRequired,
    groupItemId: string.isRequired,
    isSlideOpen: bool,
};
WrapUpModal.defaultProps = {
    isSlideOpen: false,
};
export default withStyles(styles)(WrapUpModal);
