/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React, { useState } from 'react';
import {  string, shape } from 'prop-types';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import subscriptionConfig from '../../../../../../../helpers/common/subscription/subscription-helpers';
import { getFeatureFlag } from '../../../../../../../../state/ducks/App/ducks/Config/Config-Selectors';

const useStyles = makeStyles((theme) => ({
    container: {
        borderTop: '1px dotted rgba(213, 213, 213, 1)',
        paddingTop: '8px',
    },
    expandIcon: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingBottom: '8px',
    },
    header: {
        fontSize: '14px',
        fontWeight: '400',
        color: theme.palette?.primary?.main,
    },
    label: {
        fontSize: '12px',
        fontWeight: '400',
        color: theme.palette.cta4 || '#595959',
        paddingTop: '8px',
    },
    subscriptionText: {
        fontSize: '12px',
        fontWeight: '400',
        color: theme.palette?.primary?.main,
        paddingTop: '8px',
    },
}));

const SubscriptionSummary = ({
    subscriptionData,
}) => {
    const classes = useStyles();
    const [isOpen, setModal] = useState(true);
    const disableMonthOnlyPayFullPdpSubscriptionBanner = useSelector((state) => getFeatureFlag('disable-month-only-pay-full-pdp-subscription-banner')(state));

    const isMonthOnlyPayFull = subscriptionData?.type === 'MonthOnlyPayFull' && disableMonthOnlyPayFullPdpSubscriptionBanner;
    if ((!subscriptionData?.duration && !subscriptionData?.interval) || isMonthOnlyPayFull) return null;
    return (
        <>
            <Grid item xs={12} className={classes.expandIcon}>{isOpen ? <ExpandLess data-testid="expand-less-icon" onClick={() => setModal(false)} /> : <ExpandMore data-testid="expand-more-icon"  onClick={() => setModal(true)}  />}</Grid>
            {isOpen && (
                <Grid container className={classes.container}>
                    <Grid item xs={12} className={classes.header}>Subscription:</Grid>
                    <Grid item xs={12}><span className={classes.label}>Frequency:</span> <span className={classes.subscriptionText}>{subscriptionConfig.subscription_interval[subscriptionData.interval]}</span></Grid>
                    <Grid item xs={12}><span className={classes.label}>Duration:</span> <span className={classes.subscriptionText}>{subscriptionConfig.subscription_duration[subscriptionData.duration]}</span></Grid>
                </Grid>
            )}
        </>
    );
};

SubscriptionSummary.propTypes = {
    subscriptionData: shape({
        duration: string,
        interval: string,
    }),
};
SubscriptionSummary.defaultProps = {
    subscriptionData: {},
};

export default (SubscriptionSummary);
