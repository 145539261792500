/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import {
    string, shape, arrayOf, number, bool, array,
} from 'prop-types';

export const selectedSkuType = shape({
    id: string.isRequired,
    name: string.isRequired,
    prices: arrayOf(
        shape({
            type: string.isRequired,
            value: number.isRequired,
            __typename: string.isRequired,
        }),
    ).isRequired,
    __typename: string.isRequired,
});

export const UpsellType = arrayOf(shape({
    partNumber: string,
    name: string,
    upSellDisplayPrice: string,
    image: shape({
        name: string,
    }),
}));

export const skuDataType = arrayOf(selectedSkuType);

export const clubComponentType = arrayOf(shape({
    availableDate: string,
    endDate: string,
    frequency: string,
    image: shape({
        name: string,
        path: string,
    }),
    longDescription: string,
    month: string,
    name: string,
    partNumber: string,
    productContents: string,
    shortDescription: string,
    startDate: string,
}));

export const productType = shape({
    availability: shape({
        deliveryMessage: string,
        productDeliveryType: string,
    }),
    brandId: string,
    displayDeliveryCalendar: bool,
    image: shape({
        additionalImages: array,
        altText: string,
        name: string,
        path: string,
        snipe: string,
        snipeImagePath: string,
    }),
    isPassportEligible: bool,
    isPersonalizable: bool,
    legacyId: string,
    longDescription: string,
    name: string,
    partNumber: string,
    productContents: arrayOf(string),
    productSkus: skuDataType,
    productType: string,
    seo: shape({
        url: string,
        canonicalUrl: string,
    }),
    shortDescription: string,
    sympathyValue: string,
    upsells: UpsellType,
});

export const skuConfigType = shape({
    show_optional_gift_message: bool,
    sku_display_style: string,
    sku_ranking: string,
});

export const passportImageType = shape({
    content_type: string,
    file_size: string,
    filename: string,
    is_dir: bool,
    title: string,
    uid: string,
    url: string,
});

export const deliveryDataType = shape({
    location_type_title: string,
    show: bool,
    title: string,
    zip_code_title: string,
});

export const addToCartDataType = shape({
    reqObj: shape({
        brandCode: string,
        productCode: string,
        quantity: number,
    }),
    partNumber: string,
    categoryId: string,
    categoryName: string,
    categoryPath: string,
});

export const addonGroupsType = arrayOf(shape({
    name: string,
    maxAllowedAddons: number,
    addons: arrayOf(shape({
        ageVerifyFlag: bool,
        id: string,
        legacyId: string,
        name: string,
        partNumber: string,
        prices: arrayOf(
            shape({
                type: string,
                value: number,
            }),
        ),
        image: shape({
            name: string,
            path: string,
        }),
    })),
}));

export default {

};
