/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';
import { object, string } from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    markdown: {
        textAlign: 'left',
        marginTop: '27px',
        overflowY: 'scroll',
        height: '384px',
        '& .note': {
            clear: 'both',
            padding: '10px',
            display: 'block',
            overflow: 'hidden',
            borderBottom: `1px solid ${theme.palette.modalBorder}`,
            '&:nth-child(odd)': {
                background: theme.palette.modalBorder || '#ededed',
            },
        },
        '& .desc': {
            float: 'right',
            lineHeight: '17px',
        },
    },
});

const TastingNotesBlock = ({ data, classes }) => (
    <ReactMarkdown
        source={data}
        className={classes.markdown}
        escapeHtml={false}
    />
);

TastingNotesBlock.propTypes = {
    classes: object.isRequired,
    data: string.isRequired,
};

export default withStyles(styles)(TastingNotesBlock);
