/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { string } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import SkeletonStyles from './SkeletonStyles';

const useStyles = makeStyles((theme) => ({
    ...SkeletonStyles,
    root: (styleData) => ({
        maxWidth: styleData?.width || '1400px',
        width: '100%',
        height: styleData?.height || '65vh',
        display: 'flex',
        margin: '0 auto',
        marginBottom: '30px',
        [theme.breakpoints.down(1024)]: {
            marginBottom: '16px',
        },
    }),
    textContainerSkeleton: {
        height: '100%',
        width: '30%',
        backgroundColor: 'grey',
        flex: 3,
    },
    imageContainerSkeleton: {
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        boxSizing: 'border-box',
        backgroundColor: 'blue',
        flex: 1,
    },
}));
const HeroSkeleton = ({ height, width }) => {
    const styleData = {
        width, height,
    };
    const classes = useStyles(styleData);
    return (
        <div data-testid="hero-skeleton" className={classes.root}>
            <div className={`${classes.imageContainerSkeleton} ${classes.shimmer}`} />
            <div className={`${classes.textContainerSkeleton} ${classes.shimmer}`} />
        </div>
    );
};

HeroSkeleton.propTypes = {
    height: string.isRequired,
    width: string.isRequired,
};
export default HeroSkeleton;
