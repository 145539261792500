/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React, { useRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    object, func, string, arrayOf, shape, bool,
} from 'prop-types';
import {
    Drawer, Button,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';
import {
    getWineData,
} from '../../../../../../state/ducks/App/App-Selectors';
import AgeVerificationForm from './partials/AgeVerificationForm';
import InlineAddonVerification from './InlineAddonVerification';

const styles = (theme) => ({
    contDrawer: {
        overflowX: 'hidden',
        height: '578px',
        width: '397px',
        borderRadius: '0 10px 10px 0',
        padding: '3px 5px 0px 17px',
        backgroundColor: 'rgba(242, 242, 242, 1)',
        position: 'absolute',
    },
    drawer: {
        backgroundColor: theme.palette.white,
        zIndex: '200',
    },
    dialogContent: {
        overflow: 'initial',
        '&:first-child': {
            padding: '0px',
        },
    },
    GoBackBtn: {
        color: theme.palette.white,
        backgroundColor: `${theme.palette.primaryButton} !important`,
        fontFamily: theme.typography.fontFamily,
        boxSizing: 'border-box',
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
        fontWeight: '700',
        width: '328px',
        margin: '40px 0px 1px 16px',
        height: '50px',
        fontSize: '1em',
        textTransform: 'uppercase',
        borderRadius: '28px',
        lineHeight: '1.46em',
        padding: '7px 24px 5px 24px',
    },
    modalCloseButton: {
        float: 'right',
        clear: 'both',
        cursor: 'pointer',
        margin: '10px 10px 0 0',
    },
    // only work on addons age verification on mobile
    addonsVerificationMobile: {
        position: 'relative',
        margin: 10,
        '& > div': {
            padding: 10,
        },
        '& h3': {
            fontSize: 18,
        },
        '& .age-verification::before': {
            top: 0,
        },
        '& .age-verification::after': {
            top: -25,
        },
        '& .zip-code-error::after': {
            top: 0,
        },
    },
    addonsVerification: {
        position: 'relative',
    },
});

const AddonVerification = ({
    classes,
    zipField,
    dayErrorMessage,
    monthErrorMessage,
    yearErrorMessage,
    zipFieldRef,
    getBirthDay,
    getBirthMonth,
    getBirthYear,
    handleAgeVerification,
    validateFields,
    handleZipChange,
    displayZipValidCSSCheck,
    validateZip,
    oncloseDrawer,
    wineValidation,
    addItemAddon,
    selectedItem,
    birthDay,
    birthMonth,
    birthYear,
    isModal,
    mobileAgeVerificationOpen,
    handleMobileAgeVerification,
    hasAddons,
    displayAdditionalCompliance,
    pdpInlineAddon,
    setOpen,
    open,
    handleAddToCart,
    addToCartData,
    productBrand,
}) => {
    const wineContainerRef = useRef(null);
    const getAvailability = (data) => {
        let availability = false;
        if (!data?.wineAvailability) { return false; }
        const keys = Object.keys(data.wineAvailability);
        keys.forEach((key) => {
            if (data.wineAvailability[key]?.isProductAvailable) {
                availability = true;
            }
        });
        return availability;
    };

    const onContinueDrawer = () => {
        addItemAddon(selectedItem, 'add');
        oncloseDrawer(false);
    };
    if (isModal && pdpInlineAddon) {
        return (
            <InlineAddonVerification
                isModal
                hasAddons={hasAddons}
                oncloseDrawer={oncloseDrawer}
                getBirthMonth={getBirthMonth}
                validateFields={validateFields}
                birthMonth={birthMonth}
                monthErrorMessage={monthErrorMessage}
                getBirthDay={getBirthDay}
                birthDay={birthDay}
                dayErrorMessage={dayErrorMessage}
                getBirthYear={getBirthYear}
                birthYear={birthYear}
                yearErrorMessage={yearErrorMessage}
                handleAgeVerification={handleAgeVerification}
                handleZipChange={handleZipChange}
                validateZip={validateZip}
                zipField={zipField}
                zipFieldRef={zipFieldRef}
                displayZipValidCSSCheck={displayZipValidCSSCheck}
                wineContainerRef={wineContainerRef}
                displayAdditionalCompliance={displayAdditionalCompliance}
                selectedItem={selectedItem}
                mobileAgeVerificationOpen={mobileAgeVerificationOpen}
                open={open}
                setOpen={setOpen}
                handleAddToCart={handleAddToCart}
                addToCartData={addToCartData}
                productBrand={productBrand}
            />
        );
    }
    return (
        <>
            {isModal ? (
                <>
                    <Dialog
                        PaperProps={{
                            className: hasAddons ? classes.addonsVerificationMobile : classes.addonsVerification,
                        }}
                        open={mobileAgeVerificationOpen}
                        maxWidth="xs"
                        onClose={() => { handleMobileAgeVerification(false); }}
                    >
                        <DialogContent data-testid="ageVerificationForm-modal-content" className={classes.dialogContent} ref={wineContainerRef}>
                            <CloseIcon className={classes.modalCloseButton} data-testid="ageVerificationForm-modal-content-close" onClick={() => { handleMobileAgeVerification(false); }} />
                            <AgeVerificationForm
                                isModal
                                hasAddons={hasAddons}
                                oncloseDrawer={oncloseDrawer}
                                getBirthMonth={getBirthMonth}
                                validateFields={validateFields}
                                birthMonth={birthMonth}
                                monthErrorMessage={monthErrorMessage}
                                getBirthDay={getBirthDay}
                                birthDay={birthDay}
                                dayErrorMessage={dayErrorMessage}
                                getBirthYear={getBirthYear}
                                birthYear={birthYear}
                                yearErrorMessage={yearErrorMessage}
                                handleAgeVerification={handleAgeVerification}
                                handleZipChange={handleZipChange}
                                validateZip={validateZip}
                                zipField={zipField}
                                zipFieldRef={zipFieldRef}
                                displayZipValidCSSCheck={displayZipValidCSSCheck}
                                wineContainerRef={wineContainerRef}
                                displayAdditionalCompliance={displayAdditionalCompliance}
                                selectedItem={selectedItem}
                            />
                        </DialogContent>
                    </Dialog>
                </>
            ) : (
                <div className={`drawer ${classes.drawer}`}>
                    <Drawer classes={{ paper: classes.contDrawer }} open variant="persistent" anchor="right">
                        <AgeVerificationForm
                            oncloseDrawer={oncloseDrawer}
                            getBirthMonth={getBirthMonth}
                            validateFields={validateFields}
                            birthMonth={birthMonth}
                            monthErrorMessage={monthErrorMessage}
                            getBirthDay={getBirthDay}
                            birthDay={birthDay}
                            dayErrorMessage={dayErrorMessage}
                            getBirthYear={getBirthYear}
                            birthYear={birthYear}
                            yearErrorMessage={yearErrorMessage}
                            handleAgeVerification={handleAgeVerification}
                            handleZipChange={handleZipChange}
                            validateZip={validateZip}
                            zipField={zipField}
                            zipFieldRef={zipFieldRef}
                            displayZipValidCSSCheck={displayZipValidCSSCheck}
                            selectedItem={selectedItem}
                            isDrawer
                        />
                        {getAvailability(wineValidation) && zipField.length === 5
                            ? (
                                <Button className={classes.GoBackBtn} onClick={onContinueDrawer}>
                                    CONTINUE
                                </Button>
                            )
                            : (
                                <Button className={classes.GoBackBtn} onClick={() => oncloseDrawer(false)}>
                                    GO BACK
                                </Button>
                            )}

                    </Drawer>
                </div>
            )}

        </>
    );
};

const mapStateToProps = (state) => ({
    wineValidation: getWineData(state),
});

AddonVerification.defaultProps = {
    isModal: false,
    mobileAgeVerificationOpen: false,
    handleMobileAgeVerification: () => {},
    wineValidation: null,
    hasAddons: false,
    displayAdditionalCompliance: false,
    pdpInlineAddon: false,
    open: false,
    handleAddToCart: () => {},
    addToCartData: {},
    productBrand: '',
};

AddonVerification.propTypes = {
    isModal: bool,
    classes: object.isRequired,
    zipField: string.isRequired,
    birthDay: string.isRequired,
    birthMonth: string.isRequired,
    birthYear: string.isRequired,
    dayErrorMessage: string.isRequired,
    monthErrorMessage: string.isRequired,
    yearErrorMessage: string.isRequired,
    zipFieldRef: func.isRequired,
    getBirthDay: func.isRequired,
    getBirthMonth: func.isRequired,
    getBirthYear: func.isRequired,
    handleAgeVerification: func.isRequired,
    validateFields: func.isRequired,
    handleZipChange: func.isRequired,
    displayZipValidCSSCheck: func.isRequired,
    validateZip: func.isRequired,
    oncloseDrawer: func.isRequired,
    wineValidation: shape({
        wineAvailability: arrayOf({
            isProductAvailable: bool,
        }),
    }),
    addItemAddon: func.isRequired,
    selectedItem: shape({
        id: string.isRequired,
    }).isRequired,
    mobileAgeVerificationOpen: bool,
    handleMobileAgeVerification: func,
    hasAddons: bool,
    displayAdditionalCompliance: bool,
    pdpInlineAddon: bool,
    open: bool,
    setOpen: func.isRequired,
    handleAddToCart: func,
    addToCartData: object,
    productBrand: string,
};
export default connect(mapStateToProps, null)(withStyles(styles)(AddonVerification));
