/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// libraries
import React from 'react';
import {
    func, shape, string, object,
    array,
} from 'prop-types';
import {  useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import mbpLogger from 'mbp-logger';

// redux, hooks, helpers
import { getBrand } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
import findContent from '../../../gql/queries/findContent';
import { GRAPHQL_ENV } from '../../../gql';

// components
import FlashDealContentContainer from './FlashDealContentContainer';
import useSSRMediaQuery from '../../../helpers/hooks/useSSRMediaQuery';

const FlashDealPageContainer = ({
    handleCloseTo, tracking, source, recipients,
}) => {
    const brand = useSelector(getBrand);
    const isCheckoutRecommendation = (source === 'checkout_product_recommendations');
    const device = useSSRMediaQuery();

    const contentType = source;
    const findGetContentTypeData = {
        brand: brand['domain-name'],
        contentType,
        GRAPHQL_ENV,
    };
    const findNbcWrapData = {
        brand: brand['domain-name'],
        contentType: 'wrap_up_products',
        query: '\\"title\\": \\"nbcu wrap up\\"',
        GRAPHQL_ENV,
    };
    const FLASH_DEAL_QUERY = findContent(findGetContentTypeData);
    const NBCU_WRAP_QUERY = findContent(findNbcWrapData);
    const { loading, error, data } = useQuery(FLASH_DEAL_QUERY);
    const { loading: nbcuLoading, data: nbcuWrapData } = useQuery(NBCU_WRAP_QUERY);
    if (loading || nbcuLoading) {
        return null;
    }
    if (error) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            query: FLASH_DEAL_QUERY,
            jsError: error,
            component: 'FlashDealPageContainer.js',
            message: `Failed to fetch content data for: content type ${contentType} on brand ${brand}`,
            env: GRAPHQL_ENV,
        });
    }
    if (!data) {
        mbpLogger.logWarning({
            appName: process.env.npm_package_name,
            query: FLASH_DEAL_QUERY,
            component: 'FlashDealPageContainer.js',
            message: 'No data returned for query',
            env: GRAPHQL_ENV,
        });
        return null;
    }

    const contentData = JSON.parse(JSON.stringify(data?.findContent?.content?.entries?.[0] || {}));
    const nbcuMovie = nbcuWrapData?.findContent?.content?.entries?.[0]?.wrap_up || [];
    let heading = contentData?.title || '';
    let content = contentData?.flash_deal_product || [];
    const gaTracking = contentData?.ga_tracking || null;
    const skuContent = content || [];
    if (isCheckoutRecommendation) {
        heading = contentData?.slide_out_mini_cart_title || '';
        content = (device === 'mobile' ? contentData?.mobile_part_number : contentData?.part_number) || [];
        // Changing Array to object for checkout recommendation
        content.forEach((item) => {
            skuContent.push({ sku: item });
        });
    }

    return (
        <FlashDealContentContainer isCheckoutRecommendation={isCheckoutRecommendation} heading={heading} tracking={gaTracking || tracking} brand={brand} content={skuContent} handleCloseTo={handleCloseTo} nbcuMovie={nbcuMovie} recipients={recipients} />
    );
};

FlashDealPageContainer.propTypes = {
    handleCloseTo: func,
    tracking: shape({
        eventCategory: '',
        eventAction: '',
    }),
    source: string,
    recipients: array,
};

FlashDealPageContainer.defaultProps = {
    tracking: {},
    source: 'flash_deals',
    handleCloseTo: () => {},
    recipients: [],
};

export default (FlashDealPageContainer);
